.dt_supersmallFont{
  font-size: 8px;
}

.dt_smallFont{
  font-size: 10px;
}

.dt_normalFont{
  font-size: 12px;
}